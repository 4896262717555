import React, { useEffect } from "react";

const HubSpotForm = () => {
    useEffect(() => {
        // Load the HubSpot script dynamically
        const script = document.createElement("script");
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: "48835722",
                    formId: "62b14b91-48a6-4d11-a480-b62844e0e15c",
                    target: "#hubspotForm"
                });
            }
        };
        document.body.appendChild(script);

        // Clean up script on unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div id="hubspotForm"></div>; // Target div for the form
};

export default HubSpotForm;

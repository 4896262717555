import { NavLink, NavLinks, PrimaryLink } from "components/headers/light";

export const headerNavLinks = [
    <NavLinks key={1}>
        <NavLink href="/about">
            About
        </NavLink>
        {/* <NavLink href="#">
            Blog
        </NavLink> */}
        {/* <NavLink href="#">
            Locations
        </NavLink>
        <NavLink href="#">
            Pricing
        </NavLink> */}
        <PrimaryLink href="/signup">
            Hire Us
        </PrimaryLink>
    </NavLinks>
    // ,
    // <NavLinks key={2}>
    //     <PrimaryLink href="/signup">
    //         Hire Us
    //     </PrimaryLink>
    // </NavLinks>
];